<script lang="ts" setup>
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import constants from '~/constants'

const model = defineModel<boolean>()

const props = withDefaults(
  defineProps<{
    isCallUs?: boolean
  }>(),
  { isCallUs: false }
)

function close (): void {
  model.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model">
    <template #title>
      {{ isCallUs ? 'Позвоните нам' : 'Напишите нам' }}
    </template>

    <div v-if="isCallUs" class="wrapper text-center">
      <div class="contacts">
        <a class="phone-number" :href="constants.phoneNumbers.support.href">
          <SvgIcon name="filled/phone" />
          <span>{{ constants.phoneNumbers.support.formattedText }}</span>
        </a>
      </div>
    </div>
    <template v-else>
      <div class="wrapper mb-6 text-center">
        <h4>Вопросы по&nbsp;заказам: оформление и&nbsp;оплата, статус, доставка</h4>

        <div class="contacts">
          <a v-if="false" class="cursor-pointer" :href="constants.supportWhatsAppUrl" target="_blank">
            <SvgIcon name="social-networks/whatsapp" />
            <span>WhatsApp</span>
          </a>
          <a class="cursor-pointer" :href="constants.supportTelegramUrl" target="_blank">
            <SvgIcon name="social-networks/telegram" />
            <span>Telegram</span>
          </a>
        </div>
      </div>

      <hr class="mb-6">

      <div class="wrapper text-center">
        <h4>Подбор продуктов, консультации</h4>

        <div class="contacts">
          <a class="cursor-pointer" :href="`mailto:${constants.supportEmail}`">
            <SvgIcon name="social-networks/email" />
            <span>{{ constants.supportEmail }}</span>
          </a>
        </div>
      </div>
    </template>

    <template #action>
      <a href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > h4 {
    margin-bottom: 12px;
  }

  .contacts {
    display: flex;

    a:not(:last-child) {
      margin-right: 24px;
    }
  }

  a {
    align-items: center;
    display: flex;
    text-decoration: none;

    &:hover {
      & > svg {
        color: variables.$main-blue-color;
      }

      &.phone-number > svg {
        color: variables.$main-green-color;
      }
    }

    & > svg {
      color: variables.$main-gray-color;
      font-size: 32px;
      margin-right: 12px;
    }

    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    &.phone-number > span {
      color: variables.$main-green-color;
    }
  }
}
</style>
